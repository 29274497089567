<template>
  <share-instance-modal :instance="instance">
    <template v-slot="{ on, attrs }">
      <v-chip
        v-if="shares"
        small
        v-on="on"
        @click.native.stop.prevent
        v-bind="attrs"
        color="info"
      >
        <v-icon size="12" color="white" class="mr-1">$shareIcon</v-icon>
        {{ shares }}
      </v-chip>
      <span
        v-else-if="showAssignText"
        v-on="on"
        class="label-bar-empty info--text p-4"
        style="cursor: pointer"
        >Assign User</span
      >
    </template>
  </share-instance-modal>
</template>

<script>
import ShareInstanceModal from "../modal/ShareInstanceModal.vue";

export default {
  props: {
    instance: Object,
    showAssignText: Boolean,
  },
  components: {
    ShareInstanceModal,
  },
  computed: {
    shares() {
      return this.instance.sharedAccess.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip {
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  color: white;
  font-weight: 600;
  padding: 4px;
}
</style>