<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    :transition="false"
    persistent
    ref="dialog"
    width="560px"
    :value="isOpen ? true : false"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card style="width: 560px" v-if="isOpen">
      <template v-if="Object.keys(item).length">
        <v-img
          height="285px"
          width="100%"
          position="top center"
          :src="item.screenshot_url"
        />
        <v-divider />
      </template>
      <template v-if="isOpen === 'selectProduct'">
        <v-card-title
          v-if="!Object.keys(item).length"
          class="pb-0 flex justify-space-between align-center"
        >
          <h4 class="font-weight-light">New Installation</h4>
          <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
            <v-icon>$close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text height="800px" class="px-10 pt-8">
          <h5>Choose Product</h5>
          <p class="p-3">You have multiple active services on your account.</p>
          <v-form ref="form">
            <v-select
              outlined
              :items="services"
              v-model="selectedService"
              hide-details=""
              return-object
              validate-on-blur
              class="v-input--lg"
              item-value="id"
              :menu-props="{
                offsetY: true,
                nudgeBottom: '8px',
                closeOnContentClick: false,
                contentClass:
                  'custom-dropdown-select custom-dropdown-select--onboarding',
              }"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.plan_name }} ({{ data.item.instance_count }}/{{
                  data.item.instance_limit
                }}
                Instances)<template v-if="data.item.hosting_accounts.length">
                  - {{ data.item.hosting_accounts[0].domain }}</template
                >
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.plan_name }} ({{ data.item.instance_count }}/{{
                  data.item.instance_limit
                }}
                Instances)<template v-if="data.item.hosting_accounts.length">
                  - {{ data.item.hosting_accounts[0].domain }}</template
                >
              </template>
            </v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-column pb-4 px-10 pt-2">
          <v-btn
            x-large
            elevation="0"
            @click="$emit('service-selected', selectedService)"
            color="primary"
            block
          >
            <template v-if="!item.name && selectedService.choose_theme">
              Choose Theme <v-icon size="24" class="ml-2">$arrowright</v-icon>
            </template>
            <template v-else>
              Set Website Name
              <v-icon size="24" class="ml-2">$arrowright</v-icon>
            </template>
          </v-btn>
          <v-btn
            v-if="Object.keys(item).length"
            x-large
            elevation="0"
            class="mx-0 mt-2"
            @click="$emit('change-theme-button-clicked')"
            color="gray"
            text
            block
          >
            <span class="p-1 font-weight-light gray--text text--darken-1">
              Change Theme
            </span>
          </v-btn>
          <v-btn
            v-else
            x-large
            elevation="0"
            class="mx-0 mt-2"
            @click="$emit('goBack')"
            color="gray"
            text
            block
          >
            <span class="p-1 font-weight-light gray--text text--darken-1">
              Cancel
            </span>
          </v-btn>
        </v-card-actions>
      </template>
      <template v-if="isOpen === 'selectTitle'">
        <v-card-text class="px-10 pt-8">
          <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
            <v-icon>$close</v-icon>
          </v-btn>
          <h5>Set Website Name</h5>
          <p class="p-3">No worries, it can be changed at any time later on.</p>
          <v-form ref="form">
            <v-text-field
              outlined
              placeholder="Enter website name"
              v-model="websiteName"
              hide-details=""
              :rules="siteNameRules"
              class="v-input--lg"
              validate-on-blur
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-column pb-4 px-10 pt-2">
          <v-btn
            x-large
            elevation="0"
            @click="submitForm"
            :loading="loading"
            color="primary"
            block
          >
          Create Site
            <v-icon size="24" class="ml-2">$arrowright</v-icon>
          </v-btn>
          <v-btn
            @click="
              $emit(
                services.length > 1
                  ? 'change-service-button-clicked'
                  : 'change-theme-button-clicked'
              )
            "
            x-large
            elevation="0"
            class="mx-0 mt-2"
            color="gray"
            text
            block
          >
            <span
              v-if="services.length > 1"
              class="p-1 font-weight-light gray--text text--darken-1"
            >
              Change Service
            </span>
            <span
              v-else
              class="p-1 font-weight-light gray--text text--darken-1"
            >
              Change Theme
            </span>
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRulesMixin from "../../mixins/FormRulesMixin";

export default {
  mixins: [FormRulesMixin],

  data: function () {
    return {
      selectedService: this.services[0],
      websiteName: "",
    };
  },
  props: {
    services: Array,
    loading: Boolean,
    item: Object,
    isOpen: [Boolean, String],
  },
  watch: {
    isOpen: function (open) {
      if (open) {
        this.$store.dispatch('lockBodyScroll')
        this.selectedService = this.services[0];
        if (this.services.length === 1) {
          this.$emit("service-selected", this.selectedService);
        }
      } else {
        this.$store.dispatch('unlockBodyScroll')
      }
    },
  },
  methods: {
    submitForm: function () {
      if (this.$refs.form.validate()) {
        this.$emit("installWebsite", {
          theme: this.item,
          name: this.websiteName,
        });
      }
    },
  },
  mounted: function () {
    this.selectedService = this.services[0];
    if (this.services.length === 1) {
      this.$emit("service-selected", this.selectedService, true);
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }
  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}

.custom-dropdown-select--onboarding {
  display: none !important;
}

.v-input::v-deep {
  &.v-select.v-input--lg {
    .v-input__control .v-input__slot {
      min-height: 56px;
      max-height: fit-content;
      border-radius: 8px;
      input {
        display: none;
      }
    }
  }
}

.v-input::v-deep {
  &.v-input--lg {
    .v-input__control .v-input__slot {
      border-radius: 8px;

    }
  }
}


.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>

<style lang="scss">
.close-model {
  position: absolute;
  top: -40px;
  right: -8px;
}
.custom-dropdown-select--onboarding::v-deep {
  overflow-y: auto !important;
  padding-left: 16px;
  .v-list-item {
    color: unset !important;
    caret-color: unset !important;
  }
}
</style>