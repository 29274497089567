<template>
  <div class="table-item item--select" :class="main ? 'group-start' : ''">
    <template v-if="main">
      <div class="item item--main">
        <checkbox @change="handleSelectItem" :checked="checked" />
      </div>
    </template>
  </div>
</template>

<script>
import Checkbox from "../../buttons/Checkbox.vue";

export default {
  components: { Checkbox },

  props: {
    main: {
      type: Boolean,
    },
    checked: {
      type: Boolean,
    },
    id: {
      type: [Number, String],
    },
  },
  methods: {
    handleSelectItem: function () {
      this.$emit("selectItem", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-item {
  margin-left: 8px !important;
  padding-right: 0px;
}
</style>