<template>
  <div>
    <template v-if="$vuetify.breakpoint.smAndDown && fullWidthSearch">
      <div @click="openInput" v-click-outside="() => (open = false)">
        <v-text-field
          outlined
          placeholder="Enter search term"
          :value="inputValue"
          @input="debounceSearchInput"
          prepend-inner-icon=""
          hide-details=""
          ref="input"
          class="full-width-input"
          :class="open ? 'full-width-input--open' : ''"
        >
          <template v-slot:prepend-inner>
            <v-icon class="" size="20">$search</v-icon>
          </template>
        </v-text-field>
      </div>
    </template>
    <template v-else>
      <v-text-field
        outlined
        placeholder="Enter search term"
        :value="inputValue"
        @input="debounceSearchInput"
        prepend-inner-icon=""
        hide-details=""
      >
        <template v-slot:prepend-inner>
          <v-icon class="" size="20">$search</v-icon>
        </template>
      </v-text-field>
    </template>
  </div>
</template>

<script>
import * as _ from "lodash";
export default {
  data: function () {
    return {
      open: false,
    };
  },
  props: {
    searchTermData: {
      type: String,
      default: "",
    },
    fullWidthSearch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue: {
      get: function () {
        return this.searchTermData;
      },
      set: function (newValue) {
        this.$emit("update:searchTermData", newValue);
      },
    },
  },
  methods: {
    openInput: function () {
      this.open = true;
      this.$nextTick(() => {
        this.$refs.input.$refs.input.focus();
      });
    },
    debounceSearchInput: _.debounce(function (value) {
      this.inputValue = value;
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
.v-input::v-deep {
  @media (max-width: map-get($grid-breakpoints, sm)) {
    .v-input__control {
      min-height: 38px;
      height: 38px;
      .v-text-field__slot,
      .v-input__slot {
        min-height: 38px;
        height: 38px;
      }
      fieldset {
        height: 42px;
      }
    }
  }
}

.full-width-input.v-input::v-deep {
  transition: width 0.3s ease;
  &:not(.full-width-input--open) {
    fieldset {
      border-color: transparent;
    }
  }
  .v-input__control {
    min-height: 38px;
    height: 38px;
    width: 38px;
    .v-text-field__slot,
    .v-input__slot {
      min-height: 38px;
      height: 38px;
    }
    fieldset {
      height: 42px;
    }
  }
  .v-text-field__slot {
    display: none;
    transition: max-width 0.3s ease;
  }
  .v-input__slot {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .v-input__prepend-inner {
    padding-right: 0px;
  }

  &.full-width-input--open {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    .v-text-field__slot {
      display: flex;
    }
    .v-input__prepend-inner {
      padding-right: 4px;
    }
  }
}
</style>
