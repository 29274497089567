<template>
  <v-menu
    min-width="375px"
    max-width="375px"
    offset-y
    nudge-bottom="8px"
    :close-on-content-click="false"
    :close-on-click="true"
    v-model="menuOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="filter-menu">
        <div
          v-on="on"
          v-bind="attrs"
          :class="[
            menuOpen ? 'custom-menu--active' : '',
            labelsActive ? 'custom-menu--active-info' : '',
            $vuetify.breakpoint.smAndDown ? 'custom-menu--sm p-3' : 'p-2',
          ]"
          class="custom-menu"
        >
          <span> Filters </span>
          <div v-if="appliedLabels.length > 0" class="filter-number">
            {{ appliedLabels.length }}
          </div>
          <div class="v-input__append-inner">
            <v-icon size="16">$chevrondown</v-icon>
          </div>
        </div>
        <span
          v-if="labelsActive"
          class="clear-filter text--info p-4"
          @click="removeAllLabels"
        >
          Clear Filter
        </span>
      </div>
    </template>
    <template>
      <div>
        <div class="px-4 pt-4 heading--text d-flex justify-space-between">
          <div class="p-2">Tags</div>
        </div>

        <div class="px-4 pt-2">
          <v-autocomplete
            label="Selected tags"
            hide-details
            class="pt-0 mb-4 mt-0"
            :items="labels"
            append-icon=""
            nudge-bottom="16px"
            :menu-props="{
              contentClass: 'label-autocomplete',
              disabled: true,
            }"
            v-model="selectedLabels"
            item-text="title"
            item-value="title"
            outlined
            multiple
            chips
            return-object
            :search-input.sync="searchValue"
            deletable-chips
          >
            <template v-slot:selection="{ item }">
              <label-chip
                :item="item"
                xsmall
                removable
                @unassign-label="removeLabel(item)"
              />
              <span> </span>
            </template>
          </v-autocomplete>
        </div>

        <transition name="expand-transition">
          <v-list
            class="d-flex flex-column mb-4 label-list"
            style="max-height: 304px"
          >
            <v-list-item
              v-for="(item, index) in hideSelectedLabels"
              :key="item.title + index"
              @click="addLabel(item)"
            >
              <div class="click-target"></div>
              <label-chip :item="item" xsmall />
            </v-list-item>
          </v-list>
        </transition>

        <v-divider />

        <div class="d-flex justify-space-between px-4 py-4">
          <div class="d-flex">
            <v-btn
              @click="applyLabels"
              elevation="0"
              color="primary"
              class="mr-2"
              >Apply</v-btn
            >
            <v-btn @click="closeMenu" elevation="0">Cancel</v-btn>
          </div>
          <v-btn
            @click="removeAllLabels"
            elevation="0"
            text
            color="info"
            class="clear-filter-btn"
            >Clear Filter</v-btn
          >
        </div>
      </div>
    </template>
  </v-menu>
</template>

<script>
import LabelChip from "../labels/LabelChip.vue";

export default {
  components: {
    LabelChip,
  },
  data: function () {
    return {
      searchValue: "",
      menuOpen: false,
      selectedLabels: [],
      searchOpen: false,
    };
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    appliedLabels: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    checkIfSelected: function (item) {
      if (this.selectedLabels.includes(item)) {
        return true;
      } else {
        return false;
      }
    },
    removeLabel: function (item) {
      let filtered = this.selectedLabels.filter((label) => {
        if (label.title == item.title) {
          return false;
        } else {
          return true;
        }
      });

      this.selectedLabels = filtered;
    },
    handleLabelClick: function (item) {
      if (this.checkIfSelected(item)) {
        this.removeLabel(item);
      } else {
        this.addLabel(item);
      }
    },
    addLabel: function (item) {
      this.selectedLabels.push(item);
    },
    removeAllLabels: function () {
      this.selectedLabels = [];

      this.$emit("update:appliedLabels", this.selectedLabels);
    },
    applyLabels: function () {
      this.$emit("update:appliedLabels", this.selectedLabels);

      this.closeMenu();
    },
    closeMenu: function () {
      this.menuOpen = false;
    },
  },
  computed: {
    hideSelectedLabels: function() {
      return this.filteredLabels.filter(item => {
        return !this.selectedLabels.includes(item)
      })
    },
    filteredLabels: function () {
      if (!this.searchValue) {
        return this.labels;
      } else {
        return this.labels.filter((item) => {
          return item.title.includes(this.searchValue);
        });
      }
    },
    labelsActive: function () {
      return this.appliedLabels.length > 0;
    },
  },
  watch: {
    menuOpen: function () {
      this.selectedLabels = [...this.appliedLabels];
      this.searchValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.clear-filter-btn.v-btn::v-deep {
  .v-btn__content {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

.label-autocomplete {
  overflow: auto !important;
}

.custom-menu {
  min-height: 48px;
  &.custom-menu--sm {
    min-height: 38px;
  }
  span {
    font-weight: 700;
  }
}

.v-input::v-deep {
  .v-label {
    font-size: 16px;
    line-height: 24px;
    top: 12px;
    &.v-label--active {
      top: 18px;
    }
  }
  .v-input__control .v-input__slot {
    max-height: unset;
    min-height: 48px;
  }
  .v-select__selections {
    min-height: 48px !important;
    input {
      max-height: 24px;
      margin: 0;
    }
  }
}

.v-list::v-deep {
  .v-list-item {
    max-height: 36px;
    min-height: 36px;
    position: relative;
    .click-target {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
    }
  }
  .v-list-item--active {
    background: map-get($primary, lighten4);
  }
}

.filter-menu {
  position: relative;
  .clear-filter {
    position: absolute;
    bottom: -24px;
    left: 0px;
    color: map-get($info, base);
    transition: color 0.24s ease;
    cursor: pointer;
    &:hover {
      color: map-get($info, darken1);
    }
  }
}
.label-list {
  padding: 0px 8px;
  overflow-y: auto !important;
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
    max-width: 8px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-gray-lighten2);
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
  .v-list-item {
    padding: 8px 12px;
    border-radius: 4px;
    &::before {
      background-color: currentColor;
    }
    &:hover {
      &::before {
        color: map-get($gray, base) !important;
        opacity: 0.08;
      }
    }
  }
}
</style>