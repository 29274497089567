var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-item table-item--name item--title",class:_vm.main ? 'group-start' : ''},[(_vm.main)?[_c('div',{staticClass:"item item--main"},[_c(_vm.item.hasInstallFailed() ? 'span' : 'router-link',{tag:"component",attrs:{"to":_vm.item.hasInstallFailed()
            ? undefined
            : ("/instances/" + (_vm.item.id) + "/general")}},[_c('instance-avatar',{attrs:{"instance":_vm.item,"size":40,"img":_vm.item.img,"ssl":_vm.item.info.ssl}})],1),_c('div',{staticClass:"item__title-box"},[_c(_vm.item.hasInstallFailed() ? 'span' : 'router-link',{tag:"component",staticClass:"d-flex align-center",attrs:{"to":_vm.item.hasInstallFailed()
              ? undefined
              : ("/instances/" + (_vm.item.id) + "/general")}},[_c('h6',{staticClass:"item__title text-truncate"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),(_vm.item.service_status === 'suspended')?_c('div',{staticClass:"suspend-chip ml-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"sand darken-2","size":"12"}},[_vm._v(" $suspended ")]),_vm._v(" Suspended ")],1):_vm._e()]),_c('a',{staticClass:"item__url instance-domain-link p-4",attrs:{"href":_vm.item.url}},[_vm._v(_vm._s(_vm.item.url))])],1)],1)]:[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item__title-box"},[_c('router-link',{attrs:{"to":("/instances/" + (_vm.item.subinstance.id) + "/general")}},[_c('p',{staticClass:"mb-0 p-2 font-weight-600 item__title"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"12px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline"},'div',attrs,false),on),[_c('label-chip',{staticClass:"subinstance-label",attrs:{"xsmall":"","item":{
                      title: _vm.item.subinstance.subinstanceLabel,
                      color: 'info lighten-4 info--text',
                    }}})],1)]}}])},[_c('span',[_vm._v(" This instance has "+_vm._s(_vm.item.subinstance.subinstanceLabel)+" subinstance")])]),_vm._v(" "+_vm._s(_vm.item.subinstance.title)+" ")],1)]),_c('a',{staticClass:"item__url p-4 instance-domain-link",attrs:{"href":_vm.item.subinstance.url}},[_vm._v(_vm._s(_vm.item.subinstance.url))])],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }