var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.disabled ? 'span' : 'router-link',{tag:"component",staticClass:"instance-indicator-container",attrs:{"to":_vm.disabled ? undefined : ("/instances/" + (_vm.instance.id) + "/general")}},[_c('v-menu',{attrs:{"max-width":'292px',"nudge-bottom":"20px","nudge-left":"120px","transition":"custom-tooltip","open-delay":"150","origin":"top center","center":"","bottom":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('label-simple',{attrs:{"small":true,"item":{
            classColor: ((_vm.instance.hasInstallFailed()
                ? 'error'
                : _vm.instance.colorScheme === 'primary'
                ? 'success'
                : _vm.instance.colorScheme) + "--text"),
            title: _vm.instance.subinstanceLabel,
          }}},[[(_vm.instance.hasInstallFailed())?[_c('v-icon',{attrs:{"size":"16","color":"error"}},[_vm._v("$alerterror")])]:(_vm.instance.hasActionInProgress())?[_c('loader',{attrs:{"noMargins":true,"size":14,"color":_vm.instance.colorScheme === 'primary'
                    ? 'success'
                    : _vm.instance.colorScheme}})]:_vm._e()]],2)],1)]}}])},[_c('div',{staticClass:"text-center",class:_vm.state ? 'px-4 py-4' : 'px-4 py-2'},[(!_vm.state)?[_c('span',{staticClass:"text-center p-4"},[_vm._v(" This instance has "+_vm._s(_vm.instance.subinstanceLabel)+" instance ")])]:(_vm.state === 'error')?[_c('span',{staticClass:"text-center p-4"},[_vm._v(" There was an unexpected error, "),_c('b',[_vm._v("action has failed.")])]),_c('div',{staticClass:"d-flex w-100 justify-center mt-2"},[_c('v-btn',{staticClass:"mr-2 px-2",attrs:{"max-height":"24px","elevation":"0","x-small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('restartInstallation')}}},[_c('span',{staticClass:"p-5"},[_vm._v(" Try Again ")])]),_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","max-height":"24px","x-small":"","color":"error"},on:{"click":function($event){return _vm.$emit('cancelInstallation')}}},[_c('span',{staticClass:"p-5"},[_vm._v(" Cancel Installation ")])])],1)]:(_vm.state === 'in_progress')?[_c('span',{staticClass:"text-center p-4"},[_vm._v(" Action in progress, "),_c('b',[_vm._v("management of this instance is not yet available.")])]),_c('div',{staticClass:"d-flex w-100 justify-center mt-2"},[_c('v-btn',{staticClass:"px-2",attrs:{"elevation":"0","x-small":"","max-height":"24px","color":"error"}},[_c('span',{staticClass:"px-5"},[_vm._v("Cancel Action")])])],1)]:_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }