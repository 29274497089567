<template>
  <v-menu
    translate="slide-y"
    elevation="0"
    open-on-hover
    open-delay="200"
    offset-x
    nudge-top="50%"
    nudge-left="16px"
    left
    center
    min-width="120px"
    content-class="elevation-4--before elevation-4--after elevation-4"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="status-indicator-container">
        <div
          v-if="!hasActionPending"
          v-on="on"
          v-bind="attrs"
          :class="`status-indicator status-indicator--${
            instance.status.value ? 'ok' : 'warning'
          } ${sameSize ? 'status-indicator--samesize' : '1'}`"
        >
          <v-icon v-if="instance.status.value" class="success--text" size="20">
            $okcircle
          </v-icon>
          <v-icon v-else size="16" class="error--text">
            $alertwarningfilled
          </v-icon>
        </div>
        <div v-else v-on="on" v-bind="attrs">
          <loader :noMargins="true" :size="20" color="primary" />
        </div>
      </div>
    </template>
    <template>
      <div class="status-list">
        <div
          class="d-flex flex-column status-list__group"
          v-if="statusObject.wordpress.status !== 'not-installed'"
        >
          <span class="p-4 status-list__title">WordPress</span>
          <span
            class="status-list__value d-flex align-center"
            :class="`${
              statusObject.wordpress.status ? 'success' : 'error'
            }--text`"
          >
            <v-icon
              class="status-list__icon"
              :color="statusObject.wordpress.status ? 'success' : 'error'"
              size="16"
              v-if="!hasWordpressAction"
            >
              {{
                statusObject.wordpress.status
                  ? "$alertok"
                  : "$alertwarningfilled"
              }}
            </v-icon>
            <loader
              style="display: inline"
              v-else
              :color="'primary'"
              class="mr-2"
              :size="16"
              :noMargins="true"
            />
            {{ statusObject.wordpress.value }}
          </span>
        </div>
        <div
          class="d-flex flex-column status-list__group"
          v-if="statusObject.woocommerce.status !== 'not-installed'"
        >
          <span class="p-4">WooCommerce</span>
          <span
            class="status-list__value d-flex align-center"
            :class="`${
              statusObject.woocommerce.status ? 'success' : 'error'
            }--text`"
          >
            <v-icon
              v-if="!hasWoocommerceAction"
              class="status-list__icon"
              :color="statusObject.woocommerce.status ? 'success' : 'error'"
              size="16"
            >
              {{
                statusObject.woocommerce.status
                  ? "$alertok"
                  : "$alertwarningfilled"
              }}
            </v-icon>
            <loader
              style="display: inline"
              v-else
              :color="'primary'"
              class="mr-2"
              :size="16"
              :noMargins="true"
            />
            {{ statusObject.woocommerce.value }}
          </span>
        </div>
        <div class="d-flex flex-column status-list__group">
          <span class="p-4 status-list__title">Backup</span>
          <span
            :class="`${
              instance.hasAutomaticBackup() && instance.hasRecentBackup()
                ? 'success'
                : 'error'
            }--text`"
            class="status-list__value d-flex align-start"
          >
            <template v-if="!hasBackupAction">
              <v-icon
                :color="
                  instance.hasAutomaticBackup() && instance.hasRecentBackup()
                    ? 'success'
                    : 'error'
                "
                size="16"
                class="status-list__icon mt-1"
              >
                {{
                  instance.hasAutomaticBackup() && instance.hasRecentBackup()
                    ? "$alertok"
                    : "$alertwarningfilled"
                }}
              </v-icon>
              <div class="d-flex flex-column">
                <span
                  :class="
                    !instance.hasRecentBackup() &&
                    !instance.hasAutomaticBackup()
                      ? 'mb-1'
                      : ''
                  "
                  v-if="!instance.hasRecentBackup()"
                  >No recent backups
                </span>

                <span v-if="!instance.hasAutomaticBackup()"
                  >Automatic backups are disabled
                </span>
                <span v-if="instance.hasAutomaticBackup() && instance.hasRecentBackup()">Enabled</span>
              </div>
            </template>
            <template v-else>
              <loader
                style="display: inline"
                color="success"
                class="mr-2"
                :size="16"
                :noMargins="true"
              />
              <span class="success--text"> Action in Progress </span>
            </template>
          </span>
        </div>
      </div>
    </template>
  </v-menu>
</template>

<script>
import Loader from "../Loader.vue";

export default {
  mounted() {
    this.$root.$on("backup-item-changed", (item) => {
      for (const [bkey, b] of this.instance.backup_details.entries()) {
        if (b.id === item.id) {
          this.instance.backup_details[bkey] = item;
          this.instance.backup_details = [...this.instance.backup_details];
          return;
        }
      }
    });
  },
  components: {
    Loader,
  },
  props: {
    instance: Object,
    sameSize: Boolean,
  },
  methods: {
    checkIfUpdateIsAvailable: function (version, desiredVersion) {
      if (version !== null) {
        if (version === desiredVersion) {
          return true;
        } else {
          return false;
        }
      } else {
        return "not-installed";
      }
    },
  },
  computed: {
    hasWoocommerceAction: function () {
      return false;
    },
    hasWordpressAction: function () {
      return this.instance.hasUpdateInProgress();
    },
    hasBackupAction: function () {
      for (const b of this.instance.backup_details) {
        if (!b.async_status) {
          b.async_status = {};
        }
        if (
          b.async_status.create == "pending" ||
          b.async_status.create == "in_progress" ||
          b.async_status.restore == "pending" ||
          b.async_status.restore == "in_progress"
        ) {
          return true;
        }
      }
      return false;
    },
    hasActionPending: function () {
      if (
        this.hasBackupAction ||
        this.hasWordpressAction ||
        this.hasWoocommerceAction
      ) {
        return true;
      }
      return false;
    },
    statusObject: function () {
      return {
        wordpress: {
          value: this.instance.status.wordpress,
          status: this.checkIfUpdateIsAvailable(
            this.instance.status.wordpress,
            this.instance.status.wordpress_available
          ),
        },
        woocommerce: {
          value: this.instance.status.woocommerce,
          status: this.checkIfUpdateIsAvailable(
            this.instance.status.woocommerce,
            this.instance.status.woocommerce_available
          ),
        },
        backup: this.instance.status.backup,
        backup_recent: this.instance.status.backup_recent,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content::v-deep {
  // box-shadow: none !important;
  border-radius: 6px;
  contain: none;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;

    top: 0px;
    z-index: -2;
    left: 0px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    right: -10px;
    top: 55px;
    z-index: -1;
    transform: rotate(45deg);
    transform-origin: center;
    background: white;
  }
}

.status-indicator-container {
  min-height: 32px;
}

.status-indicator {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &--ok {
    color: map-get($primary, base);
  }
  &--warning:not(.status-indicator--samesize) {
    height: 32px;
    width: 32px;
    color: map-get($error, base);
    background-color: map-get($error, lighten2);
  }
}
.status-list {
  padding: 16px;
  position: relative;
  z-index: 99;
  background: #fff;
  border-radius: 6px;
  &__group {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__title {
    color: map-get($gray, darken1);
  }

  &__value {
    font-weight: 600;
  }

  &__icon {
    margin-right: 6px;
  }
}
</style>
