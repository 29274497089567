<template>
  <v-card
    elevation="4"
    class="alert alert--error"
    @mouseover="resetTimeout"
    @mouseleave="alertTimeout"
  >
    <div class="alert__icon-box">
      <v-icon color="error" size="20">$alertwarning</v-icon>
    </div>
    <div class="alert__text-box">
      <p class="p-2 font-weight-bold mb-3">Error</p>

      <p class="mb-0 p-2">
        <template v-if="item.errorMessage"> {{ item.errorMessage }} </template>
        <template v-else-if="item.html_text">
          <div v-html="item.html_text" />
        </template>
        <template v-else> error </template>
      </p>
    </div>
    <div class="alert__close-box">
      <v-btn icon small>
        <v-icon size="16" @click="removeAlert">$close</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import AlertMixin from "../../mixins/AlertMixin";

export default {
  mixins: [AlertMixin],
};
</script>

<style lang="scss" scoped>
.alert__text-box {
  white-space: normal;
  max-width: calc(100% - 40px);
}
</style>
