<template lang="">
  <v-chip
    small
    :color="
      role === 'administrator'
        ? '#fff7e5'
        : role === 'author'
        ? '#e6f9f2'
        : role === 'contributor'
        ? '#ffeff9'
        : role === 'editor'
        ? '#ffebe0'
        : role === 'subscriber'
        ? '#ebf2fd'
        : '#f2f2f7'
    "
  >
    <span
      class="p-3"
      :style="
        role === 'administrator'
          ? 'color: #DB9C00'
          : role === 'author'
          ? 'color: #07C07E'
          : role === 'contributor'
          ? 'color: #FE33B9'
          : role === 'editor'
          ? 'color: #FF7A2F'
          : role === 'subscriber'
          ? 'color: #005EEA'
          : 'color: #797997'
      "
    >
      {{ role }}
    </span>
  </v-chip>
</template>
<script>
export default {
  props: {
    role: String,
  },
  methods: {
    getRoleColor: function (role) {
      switch (role) {
        case "administrator":
          return "yellow";
        case "author":
          return "blue";
        case "contributor":
          return "green";
        case "editor":
          return "pink";
        case "subscriber":
          return "orange";
        default:
          return "gray";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip {
  text-transform: capitalize;
  font-weight: 700;
  &::before {
    display: none;
  }
}
</style>
