<template>
  <component
    :is="disabled ? 'span' : 'router-link'"
    :to="disabled ? undefined : `/instances/${instance.id}/general`"
    class="instance-indicator-container"
  >
    <v-menu
      :max-width="'292px'"
      nudge-bottom="20px"
      nudge-left="120px"
      transition="custom-tooltip"
      open-delay="150"
      origin="top center"
      center
      bottom
      open-on-hover
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <label-simple
            :small="true"
            :item="{
              classColor: `${
                instance.hasInstallFailed()
                  ? 'error'
                  : instance.colorScheme === 'primary'
                  ? 'success'
                  : instance.colorScheme
              }--text`,
              title: instance.subinstanceLabel,
            }"
          >
            <template>
              <template v-if="instance.hasInstallFailed()">
                <v-icon size="16" color="error">$alerterror</v-icon>
              </template>
              <template v-else-if="instance.hasActionInProgress()">
                <loader
                  :noMargins="true"
                  :size="14"
                  :color="
                    instance.colorScheme === 'primary'
                      ? 'success'
                      : instance.colorScheme
                  "
                />
              </template>
            </template>
          </label-simple>
        </div>
      </template>
      <div :class="state ? 'px-4 py-4' : 'px-4 py-2'" class="text-center">
        <template v-if="!state">
          <span class="text-center p-4">
            This instance has {{ instance.subinstanceLabel }} instance
          </span>
        </template>
        <template v-else-if="state === 'error'">
          <span class="text-center p-4">
            There was an unexpected error, <b>action has failed.</b>
          </span>
          <div class="d-flex w-100 justify-center mt-2">
            <v-btn
              max-height="24px"
              class="mr-2 px-2"
              elevation="0"
              x-small
              color="primary"
              @click="$emit('restartInstallation')"
            >
              <span class="p-5"> Try Again </span>
            </v-btn>
            <v-btn
              elevation="0"
              max-height="24px"
              class="px-2"
              x-small
              color="error"
              @click="$emit('cancelInstallation')"
            >
              <span class="p-5"> Cancel Installation </span>
            </v-btn>
          </div>
        </template>
        <template v-else-if="state === 'in_progress'">
          <span class="text-center p-4">
            Action in progress,
            <b>management of this instance is not yet available.</b>
          </span>
          <div class="d-flex w-100 justify-center mt-2">
            <v-btn
              elevation="0"
              x-small
              max-height="24px"
              class="px-2"
              color="error"
            >
              <span class="px-5">Cancel Action</span>
            </v-btn>
          </div>
        </template>
      </div>
    </v-menu>
  </component>
</template>

<script>
import LabelSimple from "../labels/LabelSimple.vue";
import Loader from "../Loader.vue";

export default {
  components: {
    LabelSimple,
    Loader,
  },
  props: {
    instance: Object,
  },
  computed: {
    state: function () {
      return this.instance.hasInstallStatus()
        ? this.instance.hasInstallFailed()
          ? "error"
          : "in_progress"
        : false;
    },
    disabled: function () {
      return this.state ? true : false;
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
  pointer-events: none;
}

.label-simple {
  margin-right: 16px;
  text-transform: capitalize;
}
</style>