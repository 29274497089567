var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g(_vm._b({staticClass:"label-list",class:[
    _vm.containerClass,
    !_vm.chips ? 'label-list--simple' : '',
    _vm.maxWidth ? 'flex-nowrap w-100' : '',
    _vm.filtersActive ? 'label-list--filters' : '',
    _vm.dots ? 'label-list--dots' : '' ]},'div',_vm.attrs,false),_vm.on),[(_vm.chips)?[(!_vm.filtersActive)?_vm._l((_vm.displayLabels),function(item,index){return _c('label-chip',_vm._g({key:index,ref:"items",refInFor:true,attrs:{"item":item,"small":_vm.small,"xsmall":_vm.xsmall,"removable":_vm.removable,"clickable":_vm.filtersActive || _vm.removable}},_vm.$listeners))}):_vm._l((_vm.displayLabels),function(item,index){return _c('v-tooltip',{key:index,attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","nudge-bottom":"4px","nudge-left":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('label-chip',_vm._g({ref:"items",refInFor:true,attrs:{"item":item,"small":_vm.small,"xsmall":_vm.xsmall,"removable":_vm.removable,"clickable":_vm.filtersActive || _vm.removable}},_vm.$listeners))],1)]}}],null,true)},[_c('span',[_vm._v("Click to filter instances by this tag")])])}),(_vm.loading)?_c('span',[_c('Loader',{attrs:{"noMargins":"","size":"16"}})],1):_vm._e()]:[_vm._l((_vm.displayLabels),function(item,index){return _c('label-simple',{key:index,ref:"items",refInFor:true,attrs:{"item":item,"dot":_vm.dots,"small":_vm.small}})}),(_vm.loading)?_c('span',[_c('Loader',{attrs:{"noMargins":"","size":"16"}})],1):_vm._e()],(_vm.remainingAsButton)?[(_vm.remainingLabels > 0)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],ref:"remaining",staticClass:"label-list__items-remaining info--text p-5 font-weight-regular",on:{"click":_vm.showRemaining}},[_vm._v(" "+_vm._s(_vm.remainingLabels ? ("+" + _vm.remainingLabels) : "")+" ")]):_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],ref:"remaining",staticClass:"label-list__items-remaining info--text p-5 font-weight-regular",on:{"click":_vm.showRemaining}},[_vm._v(" Add More ")])]:[(_vm.remainingLabels > 0)?_c('div',{ref:"remaining",staticClass:"label-list__items-remaining info--text p-5"},[_vm._v(" +"+_vm._s(_vm.remainingLabels)+" ")]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }