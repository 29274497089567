<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    persistent
    ref="dialog"
    width="560px"
    v-model="isOpen"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card class="" v-if="isOpen">
      <div class="pa-6 d-flex align-center justify-center">
        <OnboardingNoActivePlans />
      </div>

      <v-divider />

      <v-card-text height="800px" class="px-10 pt-8">
        <v-btn x-small icon @click="$emit('removeModal')" class="close-model">
          <v-icon>$close</v-icon>
        </v-btn>
        <h4 class="text-center pb-6">Limit Reached</h4>
        <p class="p-1 text-center">
          You've reached your plan's limit of <b>{{ vars.limit }} instances</b>.
          <template v-if="upgradePlanLink">
            Please upgrade your plan or order a new plan
            <a :href="upgradePlanLink" target="_blank" class="info--text">here</a>.
          </template>
          <template v-else-if="orderNewServiceLink"> Please order a new plan to proceed. </template>
        </p>
      </v-card-text>
      <v-card-actions class="d-flex flex-column pb-4 px-10 pt-2">
        <v-btn
          v-if="upgradePlanLink"
          :href="upgradePlanLink"
          target="_blank"
          x-large
          elevation="0"
          color="primary"
          block
        >
          Upgrade Your Package
        </v-btn>
        <v-btn v-else-if="orderNewServiceLink" :href="orderNewServiceLink" target="_blank" x-large elevation="0" color="primary" block>
          Order New Plan
        </v-btn>
        <v-btn
          x-large
          elevation="0"
          class="mx-0 mt-2"
          @click="$emit('goBack')"
          color="gray"
          text
          block
        >
          <span v-if="!upgradePlanLink && !orderNewServiceLink" class="p-1 font-weight-light gray--text text--darken-1">
            Back to Dashboard
          </span>
          <span v-else class="p-1 font-weight-light gray--text text--darken-1">
            Maybe Later
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OnboardingNoActivePlans from "../illustrations/onboarding-no-active-plans.vue";

export default {
  components: {
    OnboardingNoActivePlans,
  },
  props: {
    vars: Object,
    isOpen: Boolean,
    selectedService: Object,
  },
  computed: {
    orderNewServiceLink() {
      return this.$store.state.home.user.order_service_link;
    },
    upgradePlanLink() {
      if (this.selectedService.id) {
        return this.selectedService.plan_upgrade_link;
      }
      return "";
    },
  },
  watch: {
    isOpen: function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.close-model {
  position: absolute;
  top: -40px;
  right: -8px;
}
.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }
  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
</style>
