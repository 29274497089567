import { render, staticRenderFns } from "./linux.vue?vue&type=template&id=cd5ace14&"
import script from "./linux.vue?vue&type=script&lang=js&"
export * from "./linux.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports