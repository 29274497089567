<template>
  <v-btn
    large
    color="primary"
    elevation="0"
    @click="loadServices"
    :loading="loadingServices"
    class="p-2 font-weight-bold"
  >
    <v-icon size="24" class="white--text">$plus</v-icon>
    Add New Instance
  </v-btn>
</template>

<script>
import Api from "@/apis/Api";

export default {
  data: function () {
    return {
      isPersistent: false,
      dialogOpen: false,
      loadingServices: false,
    };
  },
  methods: {
    loadServices(){
      this.loadingServices = true;
      Api.services().then(() => {
        this.loadingServices = false;
        this.startInstallationProcess();
      });
    },
    startInstallationProcess: function () {
      this.$store.dispatch("startInstallationProcess");
    },
  },
};
</script>

