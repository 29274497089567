import { render, staticRenderFns } from "./onboarding-no-active-plans.vue?vue&type=template&id=80acba52&"
import script from "./onboarding-no-active-plans.vue?vue&type=script&lang=js&"
export * from "./onboarding-no-active-plans.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports