<template>
  <div class="no-results-container">
    <div class="text-center" v-if="searchTerm || filterLabels.length">
      <div
        class="d-flex flex-column align-center justify-center mx-auto pa-6"
        style="max-width: 510px"
      >
        <svg
          width="65"
          height="65"
          viewBox="0 0 65 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48.396 48.0398L48.3788 48.0571C46.9997 49.4412 46.9907 51.6762 48.3586 53.0491L57.3096 62.0325C58.6776 63.4054 60.9045 63.3963 62.2836 62.0123L62.3008 61.995C63.6799 60.6109 63.689 58.3759 62.321 57.003L53.37 48.0196C52.002 46.6467 49.7751 46.6557 48.396 48.0398Z"
            fill="#EEEEF4"
            stroke="#77849F"
            stroke-width="2"
            stroke-miterlimit="10"
          />
          <path
            d="M46.27 46L48.27 48"
            stroke="#77849F"
            stroke-width="2"
            stroke-miterlimit="10"
          />
          <path
            d="M29.27 27L35.27 33"
            stroke="#77849F"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M35.27 27L29.27 33"
            stroke="#77849F"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M32.27 51C43.868 51 53.27 41.598 53.27 30C53.27 18.402 43.868 9 32.27 9C20.672 9 11.27 18.402 11.27 30C11.27 41.598 20.672 51 32.27 51Z"
            stroke="#B0B0D3"
            stroke-width="2"
            stroke-miterlimit="10"
          />
          <path
            d="M7.27002 53.0145L3.27002 49"
            stroke="#77849F"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M3.27002 53.0145L7.27002 49"
            stroke="#77849F"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M53.27 5L45.27 5"
            stroke="#B0B0D3"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M49.27 9L49.27 1"
            stroke="#B0B0D3"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M60.27 17C61.9269 17 63.27 15.6569 63.27 14C63.27 12.3431 61.9269 11 60.27 11C58.6132 11 57.27 12.3431 57.27 14C57.27 15.6569 58.6132 17 60.27 17Z"
            stroke="#77849F"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>

        <span class="gray--text text--darken-1 mb-6 mt-7">
          Try adjusting your search or filter to find what you’re looking for.
        </span>

        <v-btn
          text
          color="primary"
          @click="$emit('clearAllFilters')"
          class="px-3"
        >
          <v-icon color="primary" size="16" class="mr-2"> $xcircle </v-icon>
          <span class="p-2 font-weight-normal">Clear Filters</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchTerm: {
      type: String,
      default: "",
    },
    filterLabels: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.no-results-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 40px;
  border: 1px dashed map-get($gray, lighten2);
  border-radius: 8px;
  margin: 16px 0 32px 0;
  > div {
    + div {
      margin-top: 4px;
    }
  }
}
</style>
