<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33337 16C1.33337 16 6.66671 5.33337 16 5.33337C25.3334 5.33337 30.6667 16 30.6667 16C30.6667 16 25.3334 26.6667 16 26.6667C6.66671 26.6667 1.33337 16 1.33337 16Z"
      stroke="#333342"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
      fill="var(--v-primary-base)"
      stroke="var(--v-primary-base)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.18047 2.07555C5.00126 1.86545 4.68567 1.84041 4.47558 2.01961C4.26548 2.19881 4.24043 2.5144 4.41963 2.7245L5.18047 2.07555ZM27.6196 29.9245C27.7988 30.1346 28.1144 30.1596 28.3245 29.9804C28.5346 29.8012 28.5597 29.4856 28.3805 29.2756L27.6196 29.9245ZM4.41963 2.7245L27.6196 29.9245L28.3805 29.2756L5.18047 2.07555L4.41963 2.7245Z"
      fill="#333342"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>