<template>
  <v-tabs
    class="navbar-tabs"
    :class="isInstanceDetails ? 'tabs-none-active' : ''"
    prev-icon="$chevronleft"
    next-icon="$chevronright"
    center-active
    :value="activeRoute"
  >
    <v-tab
      v-ripple="false"
      exact
      exact-path
      :to="`/instances/`"
      exact-active-class=""
      active-class=""
      class="p-1 font-weight-600 top-bar__item"
    >
      My Instances
    </v-tab>
    <v-tab
      :disabled="isTopTabsDisabled"
      v-ripple="false"
      :to="`/hosting/`"
      class="p-1 font-weight-600 top-bar__item"
      >My Hosting</v-tab
    >
  </v-tabs>
</template>

<script>
export default {
  computed: {
    activeRoute: function () {
      return this.$route.path.includes("/hosting");
    },
    isInstanceDetails: function () {
      return (
        this.$route.path.includes("instances") &&
        this.$route.name !== "Instances"
      );
    },
    isTopTabsDisabled: function () {
      return !this.$store.state.home.user.has_active_instances
    },
  },

  // data: function () {
  //   return {
  //     matchedRoute: this.$route.matched[0].name == "Instance" ? 0 : 1,
  //   };
  // },
  // watch: {
  //   $route(to) {
  //     this.matchedRoute = to.matched[0].name == "Instance" ? 0 : 1;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.navbar-tabs.v-tabs {
  .v-tab.v-tab--active {
    color: map-get($primary, darken1) !important;
  }
}

.v-tabs::v-deep {
  .v-tabs-slider-wrapper {
    height: 3px !important;
    color: map-get($primary, base);
  }
  &.tabs-none-active {
    .v-tabs-slider-wrapper {
      opacity: 0;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .navbar-tabs.v-tabs::v-deep {
    .v-slide-group {
      &__wrapper {
        align-items: flex-end;
      }
    }

    & .v-tabs-bar {
      &__content {
        height: 37px;
      }

      .v-tab {
        height: 37px;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .v-tabs {
    margin-bottom: -5px;
  }
}
</style>
