import axios from "axios";
import store from "../store/store";
import dns from "./Dns";
import Instances from "./Instances";
import Hosting from "./Hosting";
import Auth from "./Auth";

let Api = axios.create({
  // eslint-disable-next-line
  // baseURL: paconfig.VUE_APP_API_URL || process.env.VUE_APP_API_URL
});

Api.defaults.withCredentials = true;

Api.dns = () => { return dns };
Api.instances = () => { return Instances };
Api.serverAccounts = new Map();
Api.hosting = (serverAccountId) => {
  if (!Api.serverAccounts.has(serverAccountId)) {
    Api.serverAccounts.set(serverAccountId, new Hosting(serverAccountId));
  }
  return Api.serverAccounts.get(serverAccountId);
}

Api.services = async () => {
  return Auth.home().then((response) => {
    // console.log('setting services: ', response.data.user.services);
    store.commit("setServices", response.data.user.services);
    return response.data.user.services;
  });
},

Api.cached = (seconds) => {
  return {
    get: (url, config) => {
      let time = Math.floor(Date.now() / 1000);
      if (typeof (seconds) === 'undefined' || seconds === null) {
        seconds = 36000;
      }
      if (typeof (store.state.apiCache[url]) === 'undefined') {
        let result = Api.get(url, config);
        store.dispatch('setApiCache', { url: url, result: result, time: time });
      } else if (store.state.apiCache[url].time + seconds < time) {
        let result = Api.get(url, config);
        store.dispatch('setApiCache', { url: url, result: result, time: time });
      }
      return store.state.apiCache[url].result;
    },
    clear: (url) => {
      store.dispatch('unsetApiCache', { url: url });
    },
    clearAll: () => {
      store.dispatch('clearApiCache');
    },
  }
}

Api.getErrorMessage = error => {
  if (!error.response) {
    return "Something went wrong. Please try again.";
  }
  let errorMessage = "";

  if (error.response.data.errors) {
    for (const errorGroup in error.response.data.errors) {
      for (const err of error.response.data.errors[errorGroup]) {
        if (isNaN(errorGroup)) {
          errorMessage += `${errorGroup}: `
        }
        errorMessage += err;
      }
    }
  }
  else if (error.response.data.message) {
    errorMessage = `${error.response.data.message} `;
  }
  else if (error.response.data.error) {
    errorMessage += `${error.response.data.error} `;
  }
  if (!errorMessage) {
    errorMessage = error;
  }

  return errorMessage;
}

Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Unset logged-in status when auth error code is received
    if (error.response.status === 401) {
      localStorage.removeItem(window.location.origin + "-loggedin");
      // redirect to login page
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default Api;
