import { render, staticRenderFns } from "./SelectItem.vue?vue&type=template&id=71e8d620&scoped=true&"
import script from "./SelectItem.vue?vue&type=script&lang=js&"
export * from "./SelectItem.vue?vue&type=script&lang=js&"
import style0 from "./SelectItem.vue?vue&type=style&index=0&id=71e8d620&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e8d620",
  null
  
)

export default component.exports