<template>
  <div class="alert-manager pt-4 pr-4">
    <transition-group name="list">
      <div v-for="item in alerts" :key="item.id" class="item">
        <success-alert
          v-if="item.success"
          :item="item"
          @removeAlert="removeAlert"
        />
        <error-alert v-else :item="item" @removeAlert="removeAlert" />
      </div>
    </transition-group>
  </div>
</template>

<script>
import SuccessAlert from "./SuccessAlert.vue";
import ErrorAlert from "./ErrorAlert.vue";

export default {
  components: { SuccessAlert, ErrorAlert },
  computed: {
    alerts: {
      get: function () {
        return this.$store.state.AlertsModule.activeAlerts;
      },
      set: function (newValue) {
        this.$store.commit("applyAlerts", newValue);
      },
    },
  },
  methods: {
    removeAlert: function (itemId) {
      this.$store.dispatch("removeAlert", itemId);
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-manager {
  position: fixed;
  z-index: 99999;
  top: 0px;
  right: 0px;
}

.item {
  transition: all 1s;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.list-leave-active:not(:first-child:last-child) {
  position: absolute;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(200px);
}
.list-move {
  transition: transform 0.2s ease;
}
</style>