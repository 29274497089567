<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    persistent
    ref="dialog"
    v-model="isOpen"
    absolute
  >
    <div class="card-overlay" @click="$refs.dialog.animateClick()" />

    <v-card style="width: 600px" class="" v-if="isOpen">
      <v-card-text height="800px" class="px-8 pt-8">
        <div class="mb-8 d-flex align-start">
          <div class="d-flex flex-column">
            <span class="font-weight-light p-1 base--text">
              Add New Instance
            </span>
            <h4 class>Import Existing WordPress Instance</h4>
          </div>

          <v-btn x-small icon @click="$emit('removeModal')" class="ml-auto">
            <v-icon size="24" color="gray">$close</v-icon>
          </v-btn>
        </div>

        <v-divider class="mb-5" />

        <p class="p-2 gray--text text--darken-2 mb-5 font-weight-light">
          Our system will check if it can connect to your existing WordPress instance to successfully perform the import.
        </p>

        <div class="form-box">
          <div>
            <span
              class="gray--text text--darken-1 mb-1"
              :class="urlVerificationError ? 'error--text' : ''"
            >
              Your Current WordPress Instance URL
            </span>
            <v-text-field
              v-model="url"
              outlined
              dense
              ref="input"
              :error="urlVerificationError ? true : false"
              class="mt-2 v-input--md"
              placeholder="https://"
              @input="handleInput"
              autofocus
            >
              <template v-slot:append>
                <Loader
                  v-if="urlVerificationLoading"
                  :size="20"
                  :color="'primary'"
                  :noMargins="true"
                />
              </template>
            </v-text-field>
          </div>
        </div>
        <v-scale-transition>
          <div class="error-box mt-5" v-if="urlVerificationError">
            <v-icon color="error" size="20" class="mr-2">$alertcircle</v-icon>
            <span class="error--text">
              <b class="error--text">Failed: </b>
              {{ urlVerificationError }}
            </span>
          </div>
        </v-scale-transition>
      </v-card-text>
      <v-card-actions class="pt-8 d-flex align-center justify-space-between">
        <div>
          <StepIndicator :currentStep="2" :maxSteps="4" />
        </div>
        <div class="d-flex align-center">
          <v-btn
            large
            @click="$emit('removeModal')"
            text
            elevation="0"
            class="mr-3"
          >
            <span class="font-weight-bold gray--text text--darken-1">
              Cancel
            </span>
          </v-btn>
          <v-btn
            large
            @click="$emit('next', url)"
            :disabled="!urlVerificationPassed || urlVerificationLoading"
            color="primary"
            elevation="0"
          >
            <span class="font-weight-bold">
              Continue
              <v-icon size="20" class="mr-0">$arrowright</v-icon>
            </span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as _ from "lodash";
import Api from "@/apis/Api";
import Loader from "../Loader.vue";
import StepIndicator from "../StepIndicator.vue";

export default {
  components: {
    Loader,
    StepIndicator,
  },
  props: {
    isOpen: Boolean,
  },
  data: () => ({
    url: "",
    urlVerificationPassed: false,
    urlVerificationLoading: false,
    urlVerificationError: false,
  }),
  computed: {
    isDemo() {
      return this.$store.state.home.user.email == "demo@panelalpha.com";
    },
  },
  methods: {
    handleInput: function () {
      this.urlVerificationLoading = true;
      this.debouncedVerification();
    },
    debouncedVerification: _.debounce(function () {
      this.verifyUrl();
    }, 500),
    async mockVerification() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.url) {
            resolve(true);
          } else {
            reject(
              "Following domain doesn’t contain WordPress system installed, so it can not be migrated. Please, try different domain name to continue"
            );
          }
        }, 2000);
      });
    },
    async runVerification() {
      return new Promise((resolve, reject) => {
        let params = new URLSearchParams({
          url: this.url,
        });
        Api.get(`/instances/import/test-wordpress-url?${params.toString()}`)
          .then((response) => {
            if (response.data.success) {
              resolve(true);
              return;
            }
            reject(
              "Following domain doesn’t contain WordPress system installed, so it can not be migrated. Please, try different domain name to continue"
            );
          })
          .catch((error) => {
            reject(Api.getErrorMessage(error));
          });
      });
    },
    async verifyUrl() {
      this.urlVerificationLoading = true;
      try {
        if (this.isDemo) {
          await this.mockVerification();
        } else {
          await this.runVerification();
        }
        this.urlVerificationError = false;
        this.urlVerificationPassed = true;
      } catch (error) {
        this.urlVerificationError = error;
        this.urlVerificationPassed = false;
      } finally {
        this.urlVerificationLoading = false;
      }
    },
    resetData: function () {
      this.url = "";
      this.urlVerificationPassed = false;
      this.urlVerificationLoading = false;
      this.urlVerificationError = false;
    },
  },
  watch: {
    isOpen: function (value) {
      this.resetData();

      if (value) {
        this.$nextTick(() => {
          this.$store.dispatch("lockBodyScroll");
        });
      } else {
        this.$store.dispatch("unlockBodyScroll");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.v-card::v-deep {
  .v-card__text {
    -webkit-line-clamp: unset !important;
  }

  .v-text-field--outlined > .v-input__control > .v-input__slot {
    background-color: #fff;
  }

  .form-box {
    background-color: #fcfcff;
    border: 1px solid var(--v-gray-lighten4);
    border-radius: 8px;
    padding: 16px;
  }
  .error-box {
    background-color: var(--v-error-lighten5);
    border-radius: 8px;
    padding: 16px;
    display: flex;
  }

  @media (max-width: 480px) {
    .v-card__text,
    .v-card__actions {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}
</style>
