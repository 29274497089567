var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"instance-actions__content text-center",class:_vm.state
      ? _vm.state === 'error'
        ? 'instance-actions__content--error'
        : 'instance-actions__content--pending'
      : ''},[(!_vm.state)?[_c('v-btn',{attrs:{"color":"primary","elevation":"0","small":"","max-width":"184px","min-width":"184px","loading":_vm.manageSiteLoading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.manageSite.apply(null, arguments)}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Manage Site ")])]),_c('v-btn',{staticClass:"mt-4",staticStyle:{"background":"white"},attrs:{"color":"info","elevation":"0","outlined":"","small":"","max-width":"184px","min-width":"184px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('openWpLogin')}}},[(!_vm.checkingWpLogin)?_c('v-icon',{attrs:{"color":"info","size":"16"}},[_vm._v(" $wordpressalt ")]):_c('loader',{staticClass:"mr-3",attrs:{"size":"16","color":'info',"noMargins":true}}),_c('span',{staticClass:"font-weight-bold info--text"},[_vm._v("WP Admin")])],1),(_vm.previewNotAvailable)?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"\n            preview-not-available__text\n            p-4\n            pt-4\n            gray--text\n            text--darken-2\n          "},'div',attrs,false),on),[_vm._v(" Preview not available. "),_c('span',{staticClass:"primary--text"},[_vm._v("Why?")])])]}}],null,false,3114964032)},[(_vm.instance.isNew())?_c('span',[_vm._v(" It takes few minutes after instance creation to capture a screenshot of the website. ")]):_c('span',[_vm._v(" Could not access the website to take a screenshot. DNS propagation for the domain may not be finished or DNS settings are incorrect. The preview is updated once a day. ")])]):_vm._e()]:(_vm.state === 'error')?[_c('p',{staticClass:"font-weight-bold p2 heading--text"},[_vm._v(" Installation failed. Try again. ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"small":"","color":"success","elevation":"0","max-width":"184px","min-width":"184px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('restartInstallation')}}},[_c('v-icon',{attrs:{"color":"white","size":"16"}},[_vm._v("$restore")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Try Again")])],1),_c('v-btn',{staticClass:"mt-4",attrs:{"small":"","color":"error","elevation":"0","max-width":"184px","min-width":"184px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('cancelInstallation')}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Cancel Installation")])])]:(_vm.state === 'in_progress')?[_c('p',{staticClass:"font-weight-bold p2 heading--text"},[_vm._v(" Installation in progress. Management not available yet. ")]),_c('v-btn',{attrs:{"small":"","color":"error","elevation":"0","max-width":"184px","min-width":"184px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('cancelInstallation')}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Cancel Installation")])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }