import Api from "@/apis/Api";
import Auth from "../apis/Auth";

import ActionModalMixin from "./ActionModalMixin"

export default {
  mixins: [ActionModalMixin],
  data() {
    return {
      modalRender: 0,
      modalOptions: {}
    };
  },
  methods: {
    showUnsuspendInstance(url) {
      this.resetModal();
      this.modalOptions.title = "Service Suspended";
      this.modalOptions.message = "Your site is under a service that has been <b>suspended</b>.";
      this.modalOptions.icon = "$suspended"
      this.modalOptions.color = 'sand'
      this.modalOptions.persistent = true;
      this.modalOptions.formValues = {}

      this.modalOptions.buttons.unshift({
        label: 'Unsuspend Now',
        color: 'sand darken-1',
        onclick: () => {
          this.unsuspendInstance(url)
        }
      });

      this.modalOptions.open = true;
    },
    unsuspendInstance(url) {
      window.open(url, '_blank');

      
    },
    // SSO ACTION
    showSsoModal({ application, options }) {
      if (options.length === 1) {
        this.ssoModalSingleUser({ application, options });
        return;
      }
      this.resetModal();
      this.modalOptions.title = "Login to WordPress";
      this.modalOptions.icon = "$wordpress"
      this.modalOptions.color = 'info'
      this.modalOptions.application = application
      this.modalOptions.persistent = true;
      this.modalOptions.formValues = {}

      this.modalOptions.buttons.unshift({
        label: 'Generate Login Link',
        color: 'primary',
        onclick: modal => {
          modal.$refs.form.validate() &&
            this.ssoRedirect(application, modal.formValues);
        }
      });

      this.modalOptions.formValues.user = options[0].value;
      this.modalOptions.formFields = [
        {
          label: "Login as",
          tooltip: "Login as",
          name: "user",
          type: "select",
          options: options,
        },
      ];
      this.modalOptions.submitting = false;

      this.modalOptions.open = true;
    },
    ssoModalSingleUser({ application, options }) {
      this.resetModal();
      this.modalOptions.title = "Logging in to WordPress";
      this.modalOptions.icon = "$wordpress";
      this.modalOptions.color = 'info';
      this.modalOptions.message = "Generating an SSO link in progress...";
      this.modalOptions.application = application
      this.modalOptions.persistent = true;
      this.ssoRedirect(application, { user: options[0].value });
      this.modalOptions.open = true;
    },
    ssoRedirect(application, data) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.post(
        `/instances/${application.id}/sso-url`,
        data
      ).then(response => {
        this.modalOptions.formFields = [];
        this.modalOptions.message = "Login link generated";

        let newWindow = window.open(response.data.data.sso_url, '_blank');

        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
          this.modalOptions.buttons = [{
            label: 'Log in to WordPress',
            color: 'primary',
            onclick: () => {
              window.open(response.data.data.sso_url, '_blank');
              this.modalClose();
            }
          }];
        } else {
          this.modalOptions.open = false;
        }


      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },

    // CLONE ACTION
    showStagingModal(application) {
      this.resetModal();
      this.modalOptions.title = "Create Staging";
      this.modalOptions.icon = "$copy"
      this.modalOptions.application = application

      this.modalOptions.buttons.unshift({
        label: 'Create Staging',
        color: 'primary',
        onclick: modal => {
          modal.$refs.form.validate() &&
            this.createStaging(application, modal.formValues);
        }
      });
      this.modalOptions.formFields = [
        {
          label: "Protocol",
          tooltip: "tooltip.create_staging.protocol",
          name: "protocol",
          type: "select",
          options: [
            { label: "https://", value: "https://" },
            { label: "http://", value: "http://" },
          ],
        },
        {
          label: "Domain",
          tooltip: "tooltip.create_staging.domain",
          name: "domain",
          type: "text"
        },
        {
          label: "Directory",
          tooltip: "tooltip.create_staging.directory",
          name: "dir",
          type: "text",
        },
      ];
      this.modalOptions.item = {
        protocol: "https://",
        domain: `staging.${application.domain}`,
      };
      this.modalOptions.open = true;
    },
    createStaging(application, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";

      Api.post(`/instances/${application.id}/staging`, formData).then((response) => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "Staging Created. Installation In Progress."
        });
        this.modalClose();
        this.$emit('staging-created', response.data.data);
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },
    showRetryStagingModal(application) {
      this.resetModal();
      this.modalOptions.title = "Create Staging";
      this.modalOptions.icon = "$copy"
      let stagingInstance = application;
      this.modalOptions.buttons.unshift({
        label: 'Try Again',
        color: 'primary',
        onclick: modal => {
          modal.$refs.form.validate() &&
            this.retryStaging(stagingInstance, modal.formValues);
        }
      });
      this.modalOptions.formFields = [
        {
          label: "Protocol",
          tooltip: "Protocol",
          name: "protocol",
          type: "select",
          options: [
            { label: "https://", value: "https://" },
            { label: "http://", value: "http://" },
          ],
        },
        {
          label: "Domain",
          tooltip: "Domain",
          name: "domain",
          type: "text"
        },
        {
          label: "Directory",
          tooltip: "Directory",
          name: "dir",
          type: "text",
        },
      ];
      this.modalOptions.item = {
        protocol: stagingInstance.url.startsWith('https://') ? 'https://' : 'http://',
        domain: stagingInstance.domain,
        dir: stagingInstance.dir,
      };
      this.modalOptions.open = true;
    },
    retryStaging(application, formData) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      this.modalOptions.submittingSuccess = "";
      this.modalOptions.submittingError = "";
      Api.put(`/instances/${application.staging}/retry-staging/${application.id}`, formData).then((response) => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "Staging Installation In Progress."
        });
        this.modalClose();
        this.$emit('staging-install-retry', response.data.data);
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },
    cancelStaging(stagingInstance) {
      this.$emit('staging-cancel', stagingInstance);
      Api.delete(
        `/instances/${stagingInstance.id}`,
        {
          data: {
            remove_data: true,
            remove_database: true,
          }
        }).then(() => {
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: "Staging Deleted."
          });
          this.$emit('staging-deleted', stagingInstance);
        }).catch(error => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
          this.$emit('staging-cancel-failed', stagingInstance);
        }).finally(() => {
        });
    },
    // UPDATE ACTION
    showUpdateModal({ application, availableUpdates }) {
      this.resetModal();

      this.modalOptions.title = "Update";
      this.modalOptions.icon = '$update'

      this.modalOptions.open = true;

      this.modalOptions.buttons.unshift({
        label: 'Update',
        color: 'primary',
        onclick: modal => {
          modal.$refs.form.validate() &&
            this.updateInstance(application, modal.formValues);
        }
      });

      if (!availableUpdates.length) {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "No updates available."
        });

        this.modalClose();
      } else {
        let options = [];
        for (let version of availableUpdates) {
          options.push({ label: version, value: version });
        }
        this.modalOptions.formFields = [
          {
            label: "Version",
            tooltip: "Version",
            name: "version",
            type: "select",
            options: options,
          },
          {
            label: "Create Backup",
            tooltip: "Create Backup",
            name: "create_backup",
            type: "checkbox",
          },
        ];

        this.modalOptions.formValues = { version: options[0].value };
      }

    },
    updateInstance(application, formValues) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.put(
        `/instances/${application.id}/wordpress/update`,
        formValues
      ).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "Instance Update In Progress."
        });
        this.modalClose();
        this.$emit('instance-update-in-progress', application.id);
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },
    // CLEAR CACHE ACTION
    showClearCacheModal(application) {
      this.resetModal();
      this.modalOptions.title = "Clear Cache";
      this.modalOptions.message = "Are you sure to clear cache?"
      this.modalOptions.buttons.unshift({
        label: 'Clear Cache',
        color: 'primary',
        onclick: () => {
          this.clearCache(application.id);
        }
      });
      this.modalOptions.open = true;
    },
    clearCache(application) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      let appid = ''
      if (typeof application == 'number') {
        appid = application
      } else {
        appid = application.id
      }

      Api.put(`/instances/${appid}/clear-cache`).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "Instance cache has been cleared"
        });

      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
        this.modalOptions.open = false;
      });
    },
    showMassClearCacheModal() {
      this.resetModal();
      this.modalOptions.title = "Clear Cache";
      this.modalOptions.message = "Are you sure to clear cache on selected instances?"
      this.modalOptions.icon = "$restore"

      const affectedItems = this.checkedItems.map(checkedItem => {
        return this.items.find(item => (item.id === checkedItem))
      })

      this.modalOptions.buttons.unshift({
        label: 'Clear Cache',
        color: 'primary',
        onclick: () => {
          this.massClearCache(affectedItems);
        }
      });
      this.modalOptions.open = true;
    },
    massClearCache(items) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      const clearAll = items.map(item => {
        return Api.put(`/instances/${item.id}/clear-cache`)
      })

      Promise.all(clearAll).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "Instance cache has been cleared"
        });
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
        this.modalOptions.open = false;
      });
    },
    // MASS DELETE ACTION 

    showMassDeleteModal(applications) {
      this.resetModal();
      this.modalOptions.color = 'error'
      this.modalOptions.title = "Delete Instance";
      this.modalOptions.message = `Are you sure you want to remove <b>${applications.length}</b> Instances? Any data stored on those servers will be permanently deleted, <b>this action cannot be undone!</b>`
      this.modalOptions.icon = "$alertwarning"

      this.modalOptions.buttons.unshift({
        label: 'Delete Instances',
        color: 'error',
        onclick: (modal) => {
          this.massDeleteInstance(applications, modal.formValues);
        }
      });

      this.modalOptions.formFields = [
        {
          label: "Remove Data",
          name: "remove_data",
          type: "checkbox",
        },
        {
          label: "Remove Database",
          name: "remove_database",
          type: "checkbox",
        },
        {
          message: "<b>Please confirm before you'd be able to proceed</b>",
          label: "Yes, I understand and want to remove Instance",
          name: "confirm",
          type: "checkbox",
          required: true
        },
      ];
      this.modalOptions.open = true;
    },
    massDeleteInstance(applications, data) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;

      Promise.all(applications.map(item => {
        return Api.delete(`/instances/${item.id}`,
          { data: data })
      })).then(() => {
        Auth.home(true);
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "Instances has been deleted"
        });
        this.modalClose();
        this.$emit('instance-deleted', applications);

        this.checkedItems = []
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },


    // DELETE ACTION
    showDeleteModal(application) {
      this.resetModal();
      this.modalOptions.color = 'error'
      this.modalOptions.application = application
      this.modalOptions.title = "Delete Instance";
      this.modalOptions.message = `Are you sure you want to remove <b>${this.modalOptions.application.title}</b> Instance? Any data stored on this server will be permanently deleted, <b>this action cannot be undone!</b>`
      this.modalOptions.icon = "$alertwarning"

      this.modalOptions.buttons.unshift({
        label: 'Delete Instance',
        color: 'error',
        onclick: (modal) => {
          this.deleteInstance(application, modal.formValues);
        }
      });

      this.modalOptions.formFields = [
        {
          label: "Remove Data",
          name: "remove_data",
          type: "checkbox",
        },
        {
          label: "Remove Database",
          name: "remove_database",
          type: "checkbox",
        },
        {
          message: "<b>Please confirm before you'd be able to proceed</b>",
          label: "Yes, I understand and want to remove Instance",
          name: "confirm",
          type: "checkbox",
          required: true
        },
      ];
      this.modalOptions.open = true;
    },
    deleteInstance(application, data) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;

      Api.delete(
        `/instances/${application.id}`,
        { data: data }).then(() => {
          Auth.home(true);
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: "Instance Deleted."
          });
          this.modalClose();
          this.$emit('instance-deleted', application);
        }).catch(error => {
          this.modalOptions.submittingError = Api.getErrorMessage(error);
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        }).finally(() => {
          this.modalOptions.persistent = false;
          this.modalOptions.submitting = false;
        });
    },

    // INSTALL WOOCOMMERCE ACTION
    showInstallWoocommerceModal(application) {
      this.resetModal();
      this.modalOptions.title = "Install WooCommerce";
      this.modalOptions.message = `Are you sure you want to install WooCommerce?`;
      this.modalOptions.buttons.unshift({
        label: 'Install',
        color: 'primary',
        onclick: () => {
          this.installWoocommerce(application);
        }
      });
      this.modalOptions.open = true;
    },
    installWoocommerce(application) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.post(
        `/instances/${application.id}/wordpress/plugins/new/woocommerce/install`
      ).then((response) => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "Plugin Installed."
        });
        application.setPluginsData(response.data.data);
        this.modalClose();
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },

    // PUSH TO LIVE ACTION
    showPushToLiveModal({ application, databaseDiff }) {
      this.resetModal();
      this.modalOptions.title = "Push To Live";
      this.modalOptions.icon = '$send'
      this.modalOptions.open = true;
      this.modalOptions.buttons.unshift({
        label: 'Push To Live',
        color: 'primary',
        onclick: modal => {
          modal.$refs.form.validate() &&
            this.pushToLive(application, modal.formValues);
        }
      });

      let structureChanges = [];
      for (let table of databaseDiff.tables_structure_diff) {
        structureChanges.push({ label: table, value: table });
      }
      let dataChanges = [];
      for (let table of databaseDiff.tables_data_diff) {
        dataChanges.push({ label: table, value: table });
      }
      this.modalOptions.formFields = [
        {
          label: "Overwrite files",
          name: "overwrite_files",
          type: "checkbox",
        },
        {
          label: "Push full database",
          name: "push_db",
          type: "checkbox",
        },
        {
          label: "Push structure changes in tables",
          name: "structural_change_tables",
          type: "select",
          multiple: true,
          options: structureChanges,
        },
        {
          label: "Push tables with changed data",
          name: "datachange_tables",
          type: "select",
          multiple: true,
          options: dataChanges,
        },
      ];
    },
    pushToLive(application, formValues) {
      this.modalOptions.persistent = true;
      this.modalOptions.submitting = true;
      Api.put(
        `/instances/${application.id}/push-to-live`,
        formValues
      ).then(() => {
        this.$store.dispatch("addAlert", {
          success: true,
          successMessage: "Push To Live In Progress."
        });
        this.modalClose();
        this.$emit('instance-push-to-live-in-progress', application);
      }).catch(error => {
        this.modalOptions.submittingError = Api.getErrorMessage(error);
        this.$store.dispatch("addAlert", {
          success: false,
          errorMessage: Api.getErrorMessage(error),
        });
      }).finally(() => {
        this.modalOptions.persistent = false;
        this.modalOptions.submitting = false;
      });
    },
  }
}
