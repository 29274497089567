<template>
  <v-snackbar
    class="snackbar"
    elevation="4"
    :value="checkedItems.length > 0"
    timeout="-1"
    :dark="false"
  >
    <div
      class="snackbar__row d-flex justify-space-between"
      v-if="$vuetify.breakpoint.width > 966"
    >
      <div class="d-flex align-center">
        <div class="snackbar__item-number p-2 font-weight-bold">
          {{ numberOfCheckedItems }}
        </div>
        <div class="d-flex align-center pr-2 p-2">
          {{
            numberOfCheckedItems > 1
              ? "instances selected"
              : "instance selected"
          }}
        </div>
        <div
          @click="$emit('mass-action-clear-cache')"
          v-ripple
          class="d-flex btn-icon-horizontal p-2 font-weight-600"
        >
          <v-icon>$restore</v-icon>Clear Cache
        </div>
        <v-btn color="info" @click="$emit('mass-action-share')" class="mr-3">
          <v-icon color="white" size="20">$shareIcon</v-icon>
          <span class="font-weight-bold">Share</span>
        </v-btn>
        <v-btn color="error" @click="$emit('mass-action-remove')">
          <v-icon>$xcircle</v-icon> <span class="font-weight-bold">Remove</span>
        </v-btn>
      </div>
      <div class="d-flex">
        <div
          v-ripple
          class="d-flex btn-icon-horizontal p-2 font-weight-600"
          @click="selectAll(true)"
        >
          Select All
        </div>
        <div
          v-ripple
          class="d-flex btn-icon-horizontal p-2 font-weight-600"
          @click="selectAll(false)"
        >
          Deselect
        </div>
      </div>
    </div>

    <div class="d-flex align-center" v-else style="height: 100%">
      <div class="d-flex align-center">
        <v-btn
          @click="selectAll(false)"
          x-small
          outlined
          icon
          color="primary"
          class="snackbar-button mr-3"
        >
          <v-icon size="12" color="gray darken-1">$close</v-icon>
        </v-btn>
        <div class="d-flex align-center pr-2 p-2 white--text">
          {{ numberOfCheckedItems }} Selected
        </div>
      </div>

      <div class="mx-3" style="max-width: 8px; min-width: 8px">
        <v-divider style="border-color: var(--v-gray-darken2)" />
      </div>

      <div class="d-flex align-center">
        <div
          style="cursor: pointer"
          @click="isAllSelected ? selectAll(false) : selectAll(true)"
        >
          <checkbox
            style="pointer-events: none"
            :checked="isAllSelected"
            class="mr-3"
          />
        </div>
        <div class="d-flex align-center pr-2 p-2 white--text">Select All</div>
      </div>

      <v-menu top nudge-top="36px" z-index="1001">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            x-small
            text
            icon
            color="primary"
            style="border-radius: 5px"
            class="ml-auto"
          >
            <v-icon size="20" color="gray darken-1">$morehorizontal</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="$emit('mass-action-clear-cache')">
            <!-- <v-list-item-icon> -->
            <v-icon size="20" class="mr-3">$restore</v-icon>
            <!-- </v-list-item-icon> -->
            <v-list-item-title>Clear Cache</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('mass-action-share')">
            <!-- <v-list-item-icon> -->
            <v-icon size="20" class="mr-3">$shareIcon</v-icon>
            <!-- </v-list-item-icon> -->
            <v-list-item-title>Share</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$emit('mass-action-remove')"
            color="error"
            class="error--text"
          >
            <!-- <v-list-item-icon> -->
            <v-icon size="20" class="mr-3">$xcircle</v-icon>
            <!-- </v-list-item-icon> -->
            <v-list-item-title>Remove</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-snackbar>
</template>

<script>
import SnackBarMixin from "../../mixins/SnackbarMixin";
import Checkbox from "../buttons/Checkbox.vue";

export default {
  components: {
    Checkbox,
  },
  mixins: [SnackBarMixin],
};
</script>

<style lang="scss" scoped>
.snackbar-button.v-btn {
  border-radius: 5px;
  border: 1px solid var(--v-gray-darken2) !important;
  max-width: 24px !important;
  max-height: 24px !important;
  min-height: 24px !important;
  min-width: 24px !important;
  padding: 0 !important;
  &:hover {
    background-color: transparent !important;
    border: 1px solid var(--v-primary-darken1) !important;
  }
}

.checkbox::v-deep {
  .v-input--checkbox:not(.active) .v-input--selection-controls__input {
    background-color: transparent;
    border: 1px solid var(--v-gray-darken2) !important;
  }
}

.v-list {
  .v-list-item {
    color: var(--v-gray-darken1);
    min-height: 36px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &.error--text {
      .v-list-item__title,
      .v-icon {
        color: var(--v-error-base);
      }
    }
    .v-list-item__title {
      font-size: 14px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}
</style>
